// ** React
import React, { useState } from 'react'
import {
	Route,
} from "react-router-dom";

// ** Styles
import './App.css'

const onCheckout = () => {
	fetch('api/create-payment-intent', {
		method: 'POST'
	})
		.then((res) => res.json())
		.then((data) => window.location.href = data.url)
}

export default function App() {
	const ProductDisplay = () => (
		<section>
			<div className="product">
				<img
					src="https://apparyllis.com/wp-content/uploads/2021/12/SimplyPlural-NoBg.png"
					alt="Simply Plural Logo" style={{ width: "200px" }}
				/>
				<div className="description">
					<h3>Simply Plural Lanyard</h3>
					<h5>€ 25.00 + VAT</h5>
				</div>
			</div>
			<button onClick={onCheckout}>
				Get a lanyard
			</button>
		</section>
	);

	return <ProductDisplay />;
}
